.portal-chart-tabs-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.portal-chart-tabs-root {
  text-transform: none;
  height: 95px;
}

.portal-chart-tabs-wrapper {
  flex-direction: column-reverse;
}

.portal-trend-up {
  color: #4caf50;
}

.portal-trend-down {
  color: #f44336;
}

.portal-trend-icon {
  width: 40px;
  height: 40px;
  padding-right: 5px;
}

.portal-tab-chart {
  padding: 24px;
  position: relative;
  height: 400px;
}