
.digital-welcome {
    padding-bottom: 124px;
}

.digital-welcome .sec-left { 
    margin: 0 0;
    padding-bottom: 20px;
}


.foo::before {
    background: var(--background);
    content: '';
    display: block;
    width: 200px;
    height: 300px;
  }


@media (max-width: 1199px){
    .digital-welcome {
        padding-bottom: 10px;
    }
}
@media (max-width: 991px){
 

    .digital-welcome .sec-left {
        padding-bottom: 0;
    }

    .digital-welcome {
        padding-bottom: 38px;
    }
}
@media (max-width: 767px){
    .digital-welcome .sec-left {
        margin-top: 10px;
    }

 


    .digital-welcome {
        padding-bottom: 20px;
    }
}