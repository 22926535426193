.form_field_wrap {
  background: #fff;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
  border-radius: 5px;
  padding: 30px 30px;
  margin-bottom: 38px;
}

.configure-form-sec {
  padding: 20px 0 87px;
}

.form_field_wrap .form_field {
  max-width: 845px;
}

.users-info-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.users-info-col {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
  width: 30.6%;
  margin-right: 4.1%;
}

.users-info-col:nth-child(3n),
.users-info-col:last-child {
  margin-right: 0;
}

.info-head {
  border-bottom: 2px solid #f5f7fa;
  padding: 37px 20px 31px;
}

.info-head .custom-checkbox {
  display: inline-block;
  vertical-align: middle;
}

.info-head h4 {
  margin: 0;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: middle;
}

.info-head .custom-checkbox label {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  color: #1073c6;
  text-transform: uppercase;
}

.info-head .custom-checkbox input[type="checkbox"] + label {
  padding-left: 41px;
  line-height: 20px;
}

.info-body-row .custom-checkbox {
  display: inline-block;
  vertical-align: middle;
}

.user-pic {
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 100%;
  margin-right: 10px;
}

.info-body-row {
  border-bottom: 1px solid #f5f7fa;
  padding: 10px 0;
}

.info-body {
  padding: 0 20px;
}

.info-body-row .custom-checkbox input[type="checkbox"] + label {
  padding-left: 41px;
  font-weight: 400;
}

.messages-text {
  display: inline-block;
  vertical-align: middle;
  border-radius: 5px;
  padding: 18px 20px 15px;
  width: 44%;
  margin-right: 30px;
  max-width: calc(50% - 35px);
  position: relative;
}

.messages-text p strong {
  font-weight: 700;
}

.error-msg-text {
  background: #f2dede;
  color: #a94442;
}

.success-msg-text {
  color: #3c763d;
  background: #dff0d8;
}

.messages-text p {
  margin: 0;
  font-family: Raleway, sans-serif;
}

.messages-text p img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-top: -3px;
}

.form-messages {
  margin-top: 30px;
}
.users-info-wrap .custom-checkbox input[type="checkbox"] + label:after {
  background-image: url("../../../assets/images/white-check-ic.png");
  background-color: #1073c6;
  background-size: 12px;
}
.info-head .custom-checkbox input[type="checkbox"] + label:before,
.info-head .custom-checkbox input[type="checkbox"] + label:after {
  top: 5px;
}
.configure-form-sec .form-submit .button-field input {
  min-width: 185px;
}
.form_field_wrap .field-right select {
  background-size: 10px;
  background-position: center right 20px;
}
.form-messages {
  position: fixed;
  top: 10%;
  left: 27%;
  background-position: center;
  align-self: center;
  width: 100%;
  margin: 0;
  z-index: 10000;
  padding: 10px 40px;
}
.form-messages_Bottom {
  position: fixed;
  bottom: 20px;
  right: 0;
  width: 100%;
  z-index: 1000;
  margin: 0;
  padding: 10px 40px;
  z-index: 500;
}
.form-messages_BottomLogin {
  position: fixed;
  top: 115px;
  left: 2.8%;
  width: 102%;
  z-index: 1000;
  margin: 0;
  padding: 10px 40px;
  z-index: 500;
}

.form-messages_Bottom {
  position: fixed;
  bottom: 20px;
  right: 0;
  width: 100%;
  margin: 0;
  padding: 10px 40px;
  z-index: 500;
}
.form-messages_BottomLogin {
  position: fixed;
  top: 115px;
  left: 2.8%;
  width: 102%;
  margin: 0;
  padding: 10px 40px;
  z-index: 500;
}

.main-content {
  position: relative;
}
.alert-close-ic {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}

@media (max-width: 1500px) {
  .info-head .custom-checkbox input[type="checkbox"] + label:before,
  .info-head .custom-checkbox input[type="checkbox"] + label:after {
    top: 5px;
  }

  .info-head {
    padding: 0px;
  }

  .info-body-row {
    padding: 7px 0;
  }
  .form-messages {
    padding: 10px 25px;
  }
}

@media (max-width: 1366px) {
  .users-info-col {
    width: 31.33%;
    margin-right: 3%;
  }
}
@media (max-width: 1199px) {
  .users-info-col {
    width: 100%;
    margin: 0 0 30px;
  }

  .configure-form-sec {
    padding: 10px 0 100px;
    max-width: 990px;
  }

  .form_field_wrap {
    margin-bottom: 30px;
  }

  .users-info-col:last-child {
    margin-bottom: 0;
  }
  .messages-text p img {
    max-width: 23px;
    margin-right: 6px;
  }

  .messages-text.success-msg-text {
    padding: 18px 15px 15px 10px;
  }

  .messages-text p {
    font-size: 14px;
  }

  .alert-close-ic {
    right: 15px;
  }
  .messages-text {
    max-width: calc(50% - 25px);
    margin-right: 20px;
    width: 47%;
  }
  .messages-text:last-child,
  .messages-text:nth-child(2n) {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .configure-form-sec {
    padding: 8px 0 100px;
  }

  .form_field_wrap .form_field {
    max-width: 480px;
  }

  .form_field_wrap .form_field .field-left {
    width: 36%;
  }

  .form_field_wrap .form_field .field-right {
    width: 64%;
  }

  .info-head {
    padding: 34px 20px 31px;
  }

  .info-body-row {
    padding: 20px 0 19px;
  }

  .configure-form-sec .form-submit .button-field input {
    min-width: 143px;
  }
}
@media (max-width: 767px) {
  .form_field_wrap .form_field .field-left {
    width: 100%;
  }

  .form_field_wrap .form_field .field-right {
    width: 100%;
  }

  .info-head .custom-checkbox label {
    font-size: 6px;
  }

  .configure-form-sec {
    padding: 0 0 110px;
  }

  .form_field_wrap {
    padding: 14px 15px 16px;
    margin-bottom: 20px;
  }

  .info-head {
    padding: 19px 16px 20px;
  }

  .info-head .custom-checkbox input[type="checkbox"] + label {
    padding-left: 34px;
  }

  .users-info-wrap .custom-checkbox input[type="checkbox"] + label:before {
    width: 18px;
    height: 18px;
    top: 0;
  }

  .info-body {
    padding: 0 14px;
  }

  .info-body-row {
    padding: 19px 0;
  }

  .user-pic {
    width: 20px;
    height: 20px;
  }

  .info-body-row .custom-checkbox input[type="checkbox"] + label {
    padding-left: 35px;
  }

  .users-info-col {
    margin-bottom: 20px;
  }

  .users-info-wrap {
    margin-bottom: 30px;
  }

  .configure-form-sec .form-submit .button-field input {
    min-width: 108px;
  }
  .form_field_wrap .form_field {
    max-width: 315px;
  }
  .form-messages {
    bottom: 0px;
  }
  .messages-text {
    width: 100%;
    max-width: 100%;
    margin: 0 0 10px;
    padding: 13px 15px;
  }

  .messages-text p img {
    max-width: 20px;
  }
}
