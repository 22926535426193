.header-logo {
  text-align: center;
  background: #fff;
  padding: 40px 20px;
  box-shadow: 0px 4px 6px rgba(10, 10, 10, 0.12);
}

.header-logo a {
  display: inline-block;
  vertical-align: middle;
  max-width: 308px;
}

.header-logo a img {
  display: block;
}

.error-show {
  color: #dc3545;
  position: absolute;
  top: 60%;
  right: 0;
  transform: translateY(-50%);
  padding-right: 50px;
}

.error-show:after {
  content: "";
  position: absolute;
  top: 0;
  right: 20px;
  background-image: url("../../../assets/images/alert-ic.svg");
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-repeat: no-repeat;
}

.inner-banner {
  background: rgb(10, 92, 127);
  background: -moz-linear-gradient(175deg,
      rgba(10, 92, 127, 1) 0%,
      rgba(21, 136, 186, 1) 100%);
  background: -webkit-linear-gradient(175deg,
      rgba(10, 92, 127, 1) 0%,
      rgba(21, 136, 186, 1) 100%);
  background: linear-gradient(175deg,
      rgba(10, 92, 127, 1) 0%,
      rgba(21, 136, 186, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a5c7f", endColorstr="#1588ba", GradientType=1);
  color: #fff;
  padding: 35px 30px 120px;
  text-align: center;
}

.inner-banner h1 {
  color: #fff;
  font-size: 40px;
  text-transform: uppercase;
}

.inner-banner p {
  font-size: 26px;
}

.registration-form-main {
  padding-bottom: 100px;
}

.registration-form-main form {
  /* background: #cccccc; */
  background: #ececec;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.2);
  border-radius: 5px;
  max-width: 1320px;
  width: 100%;
  margin: -90px auto 0;
  padding: 30px 50px;
}

.registration-form-wrap {
  border-bottom: 1px solid #ebebeb;
  padding: 0 10px;
  margin-bottom: 50px;
  padding-bottom: 22px;
}

.registration-form-wrap select {
  color: #646464;
}

.header-logo a img {
  display: block;
}

.registration-form-wrap .field-left {
  width: 36%;
  padding-top: 20px;
}

.registration-form-wrap .field-right {
  width: 64%;
  position: relative;
}

.registration-form-wrap .field-left label {
  font-size: 18px;
}

.registration-form-wrap .form_field label span {
  color: #ff0000;
  margin-left: 3px;
}

.registration-form-wrap .form_field {
  margin-bottom: 27px;
  align-items: flex-start;
  align-items: center;
  justify-content: center;
}

.textField {
  /* background-color:#3C763D; */
  border-radius: 4px;
  border: "0px solid #f1f1f5";
  /* color: '#32373c',
    fontFamily:'Nunito Sans' ,
    fontSize: AppConstants.FONTSIZE.FS16,
    fontWeight: 600,
    height:'62px',
    lineHeight: 1.57 */
}

.form_test {
  color: #ff0000;
  background-color: #ff0000;
  margin-left: 3px;
}

.form_field p small {
  color: rgba(100, 100, 100, 0.5);
  font-size: 14px;
  font-weight: 400;
  font-family: Raleway, sans-serif;
  display: block;
  margin-top: 9px;
}

.registration-form-wrap .form_field p {
  margin: 0;
}

.space-p {
  margin-left: 20px;
}

.field-field-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 0;
}

.field-field-col {
  width: 50%;
  padding: 0 10px 0 0;
  margin-bottom: 10px;
}

.field-field-col1 {
  width: 33.33%;
  padding: 0 10px;
  margin-bottom: 30px;
}

.field-field-row {
  margin-bottom: 15px;
}

.field-field-label {
  margin-bottom: 8px;
  font-family: Raleway, sans-serif;
  font-weight: 900;
}

.registration-form-wrap .form_field:only-child {
  margin-bottom: 0;
}

.form_field .input-file-trigger {
  display: flex;
  border: 1px dashed #dddddd;
  cursor: pointer;
  border-radius: 0;
  width: 100%;
  height: 150px;
  background: #fff;
  font-size: 16px;
  font-weight: 400;
  color: rgba(100, 100, 100, 0.5);
  align-items: center;
  justify-content: center;
}

.form-file-input {
  position: relative;
}

#fileupload {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.form_field .input-file-trigger img {
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.registration-form-wrap .field-right>p {
  font-family: Raleway, sans-serif;
  margin: 11px 0 0;
}

.registration-form-wrap .field-right:only-child {
  text-align: center;
}

.registration-form-wrap .field-right:only-child .button-field {
  display: inline-block;
  vertical-align: middle;
  min-width: 184px;
}

.registration-form-wrap .field-right .custom-checkbox {
  margin-bottom: 30px;
}

.registration-form-wrap .custom-checkbox input[type="checkbox"]+label:after,
.registration-form-wrap .custom-checkbox input[type="checkbox"]+label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.registration-form-wrap .custom-checkbox input[type="checkbox"]+label:after {
  background-image: url("../../../assets/images/blue-check-ic.png");
  background-size: 15px;
}

.registration-form-wrap .form_field .custom-checkbox label {
  font-family: Raleway, sans-serif;
  font-weight: 400;
  padding-left: 35px;
}

.registration-form-wrap:last-child {
  border: none;
  padding-bottom: 0;
  margin: 0;
}

.registration-form-wrap:last-child .form_field:last-child {
  margin-bottom: 0;
  margin-top: 20px;
}

.field-field-row-wrap:only-child .field-field-col {
  margin-bottom: 0;
}

.field-field-row:only-child {
  margin-bottom: 0;
}

.registration-form-wrap .field-right .form-file-input+p {
  margin-top: 18px;
}

.logo-preview {
  margin-top: 30px;
  position: relative;
  background: #f5f7fa;
  padding: 15px;
}

.close-ic {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-ic img {
  display: block;
}

.footer-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 1420px;
  margin: 0 auto;
}

.thank-you-page {
  background: #fff;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
  border-radius: 5px;
  max-width: 1320px;
  margin: 30px auto;
  text-align: center;
  padding: 60px 150px 55px;
}

.thank-you-page img {
  display: block;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 80px;
}

.thank-you-page h1 {
  font-size: 50px;
  color: #3c763d;
  margin-bottom: 20px;
}

.thank-you-page p {
  font-size: 24px;
  font-family: Raleway, sans-serif;
  line-height: 1.5;
}

.thank-you-page p:last-child {
  margin-bottom: 0;
}

.thank-you-page p a {
  color: #3c763d;
  text-decoration: underline;
}

@media (max-width: 1500px) {
  .registration-form-main {
    padding: 0 50px 100px;
  }

  .registration-form-main form {
    max-width: 100%;
  }

  .thank-you-page {
    max-width: calc(100% - 100px);
    padding: 25px 50px;
  }

  .thank-you-page h1,
  .inner-banner h1 {
    font-size: 38px;
  }

  .thank-you-page p {
    font-size: 22px;
  }

  .inner-banner p {
    font-size: 22px;
  }
}

@media (max-width: 1199px) {
  .header-logo {
    padding: 30px 20px;
  }

  .header-logo a {
    max-width: 270px;
  }

  .inner-banner p {
    font-size: 26px;
  }

  .inner-banner {
    padding: 70px 30px 135px;
  }

  .inner-banner h1 {
    margin-bottom: 10px;
  }

  .registration-form-wrap {
    padding: 0 50px;
    padding-bottom: 20px;
  }

  .registration-form-main form {
    padding: 80px 30px 80px;
  }

  .registration-form-wrap .field-left label {
    font-size: 16px;
  }

  .registration-form-wrap .field-right>p {
    margin: 8px 0 0;
  }

  .registration-form-wrap .field-right .form-file-input+p {
    margin-top: 15px;
  }

  .thank-you-page {
    margin: 50px auto;
    padding: 50px 80px;
  }

  .thank-you-page p {
    font-size: 20px;
  }

  .thank-you-page img {
    max-width: 120px;
    width: 80px;
  }

  .thank-you-page h1 {
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .inner-banner h1 {
    font-size: 50px;
  }

  .inner-banner h1 {
    font-size: 40px;
  }

  .inner-banner p {
    font-size: 20px;
  }

  .registration-form-wrap {
    padding: 0 20px;
    padding-bottom: 10px;
    margin-bottom: 30px;
  }

  .registration-form-main form {
    padding: 60px 30px;
  }

  .registration-form-wrap .field-left {
    width: 40%;
  }

  .registration-form-wrap .field-right {
    width: 60%;
  }

  .registration-form-wrap .form_field label span {
    margin-left: 0;
  }

  .form_field p small {
    font-size: 13px;
    margin: 2px 0 0;
  }

  .registration-form-wrap .field-right>p {
    margin: 2px 0 0;
  }

  .field-field-col {
    padding: 0 5px;
  }

  .field-field-row-wrap {
    margin: 0 -5px;
  }

  .field-field-col,
  .field-field-row,
  .registration-form-wrap .form_field {
    margin-bottom: 25px;
  }

  .form_field .input-file-trigger {
    font-size: 16px;
    height: 120px;
  }

  .form_field .input-file-trigger img {
    max-width: 14px;
    margin-right: 5px;
  }

  .registration-form-wrap .field-right>p {
    font-size: 13px;
  }

  .registration-form-wrap .field-right .form-file-input+p {
    margin-top: 10px;
  }

  .registration-form-wrap:last-child .form_field:last-child {
    margin-top: 40px;
  }

  .registration-form-main {
    padding: 0 20px 60px;
  }

  .thank-you-page h1 {
    font-size: 40px;
    margin-bottom: 10px;
  }

  .thank-you-page {
    padding: 50px;
  }

  .thank-you-page p {
    font-size: 18px;
  }

  .thank-you-page img {
    max-width: 100px;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .registration-form-wrap .field-left {
    width: 100%;
    padding-top: 0;
    margin-bottom: 7px;
    padding-right: 0;
  }

  .registration-form-wrap .field-right {
    width: 100%;
  }

  .registration-form-main form {
    padding: 40px 30px;
  }

  .registration-form-wrap .field-left label {
    font-size: 14px;
  }

  .form_field .input-file-trigger {
    font-size: 14px;
    padding: 10px;
  }

  .thank-you-page p {
    font-size: 16px;
  }
}

@media (max-width: 639px) {
  .header-logo a {
    max-width: 200px;
  }

  .header-logo {
    padding: 15px;
  }

  .inner-banner h1,
  .thank-you-page h1 {
    font-size: 30px;
  }

  .inner-banner {
    padding: 40px 30px 75px;
  }

  .inner-banner p {
    font-size: 16px;
  }

  .registration-form-main form {
    margin: -60px auto 0;
    padding: 30px 20px;
  }

  .registration-form-wrap {
    padding: 0;
  }

  .field-field-col {
    width: 100%;
    padding: 0;
  }

  .field-field-row-wrap {
    margin: 0;
  }

  .field-field-row-wrap:only-child .field-field-col {
    margin-bottom: 25px;
  }

  .field-field-row-wrap:only-child .field-field-col:last-child {
    margin-bottom: 0;
  }

  .thank-you-page {
    padding: 30px;
    margin: 30px auto;
    max-width: calc(100% - 30px);
  }

  .thank-you-page img {
    max-width: 70px;
  }
}

@media (max-width: 479px) {
  .captcha-code {
    transform: scale(0.8);
    margin-left: -12%;
  }
}