.download-btn {
  position: absolute;
  top: -5px;
  right: -15px;
  width: 10px;
  height: 10px;
  color: #ccc;
  min-width: 169px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  font-family: Raleway, sans-serif;
}

.table-row-even {
  background: #EBEBEB;
  color: #000;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: lighter;
}

.table-row-odd {
  color: #000;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: lighter;
}

.table-hed {
  background: #CCCCCC;
  width: 100%;
  font-weight: 900;
  color: #000;
  font-family: Raleway, sans-serif;
  font-size: 20px;
  line-height: 20px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.table-column {
  width: 100%;
  padding: 7px;
  letter-spacing: 0px;
  color: #0a0a0a;
  font: normal normal bold 18px/22px Raleway;
}

.table-column1 {
  width: 100%;
  padding: 10px;
  color: #4b4c4c;
  font: normal normal 16px/19px Raleway;
  letter-spacing: 0px;
}

.title-content {
  font: normal normal normal 16px/19px Raleway;
  letter-spacing: 0.4px;
  color: #646464;
}

.title {
  font: normal normal bold 25px/30px Raleway;
  letter-spacing: 0px;
  color: #0a5c7f;
  opacity: 1;
}

.title-main {
  font: normal normal bold 18px/22px Raleway;
  letter-spacing: 0px;
  color: #4b4c4c;
}

.donut-title {
  font: normal normal normal 16px/19px Raleway;
  letter-spacing: 0.32px;
  color: #646464;
  opacity: 0.9;
}